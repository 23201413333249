
// Fonts definition
@font-face {
    font-family: 'Neutra2Text-Demi';
    font-weight: normal;
    src:    url('/build/fonts/Neutra2Text-Demi.otf');
}
@font-face {
    font-family: 'Neutra2Text-Bold';
    font-weight: normal;
    src:    url('/build/fonts/Neutra2Text-Bold.otf');
}
@font-face {
    font-family: 'Neutra2Text-Book';
    font-weight: normal;
    src:    url('/build/fonts/Neutra2Text-Book.otf');
}