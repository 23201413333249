// Base Colors
$lighter-grey: #F4F3F2;
// $light-grey: #91877b19; // uses transparency for some reason, is this used ?
$light-grey: rgb(63, 63, 61); // unused ?
// $darker-light-grey: #91877b95;  // uses transparency for some reason
$darker-light-grey: #7d7468;  // seems unused but breaks links if removed ...
$lesser-dark-grey: #7d7468;
// $lesser-dark-grey: #91877BD1;   // unused ?
$dark-grey: #91877B;

// Variables
$header-txt-color: #91877B;
$footer-txt-color: white;
$footer-bg-color: rgba(145, 135, 123, 0.75);
$h1-color: #91877B;
$h2-color: black;
$h3-color: #91877B;
$h4-color: #91877B;
$h5-color: black;
$text-color: black;
$form-bg-color: #91877B;
$form-lbl-color: #91877B;
$bg-color: #91877b19;

$paginator-txt-color: #91877B;
$paginator-border-color-active: #91877B;
$paginator-border-color-inactive: white;
$paginator-border-width: 1px;
$paginator-bg-color: none;

$menu-border-color: #91877B;
$th-color: #91877B;

// Bootstrap
$theme-colors: (
  "primary": $header-txt-color,
  "secondary": $footer-txt-color
);