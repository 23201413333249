// ck-bouton-style
.ck-btn-primary.focus, .ck-btn-primary:focus {
    background-color: $dark-grey;
    border-color: $dark-grey;
    box-shadow: none;
}
.ck-btn-primary {

    display:flex;
    justify-content: center;

    a{
        border-radius:0;
        background-color: #91877B;
        border-color: #91877B;
        cursor: pointer;
        color:white;
        font-family: 'Neutra2Text-Book';
        font-size: 17px;
        font-weight: 400;
        letter-spacing: initial;
        position: relative;
        padding: 0.6rem 35px 0.6rem 55px;
        align-items: center;
        &:hover{
            text-decoration: none;
        }
        &::after {
            content: "\f061";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            position: absolute;
            left: 20px;
            color: white;
        }
        &.back::after{
            content: "\f060";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            position: absolute;
            left: 20px;
            color: white;
        }
        &.no-arrow::after{
            content:"";
        }
    }
}

// GM font style override
.gm-style * {
    font-family: "Neutra2Text-Book"!important;
}

// Common titles
.circuit-title {
    font-family: "Neutra2Text-Book";
    font-size: 30px !important;
    line-height: initial !important;
}
h1 {
    color: $h1-color;
    font-family: "Neutra2Text-Book";
    font-size: 30px;
    letter-spacing:0px;
}
h2 {
    color: $h2-color;
    font-family: "Neutra2Text-Book";
    font-size: 20px;
    margin: 0px;
    padding: 0px;
    line-height: normal!important;
}
h3 {
    color: $h3-color;
    font-family: "Neutra2Text-Bold";
    font-size: 17px;
    margin: 0px;
    padding: 0px;
}
h4 {
    color: $h4-color;
    font-family: "Neutra2Text-Bold";
    font-size: 20px;
    margin: 0px;
    padding: 0px;
}
h5 {
    color: black;
    font-family: "Neutra2Text-Book";
    font-size: 25px;
    margin: 0px;
    padding: 0px;
}
h6, .slogans-titles {
    color: white;
    font-family: "Neutra2Text-Book";
    font-size: 5vw;
    margin: 0px;
    padding: 0px;
    text-align: center;
}
.published-date-p {
    font-family: "Neutra2Text-Demi";
    color: black;
    font-size: 17px;
}
.next-previous-button {
    font-family: "Neutra2Text-Demi";
    color: $h1-color;
    font-size: 20px;
}

.btn-primary.focus, .btn-primary:focus{
    background-color: $dark-grey;
    border-color: $dark-grey;
    box-shadow: none;
}

.right-menu{
    position:relative;
    .languages-selector{
        @include media-breakpoint-down(md) {
            display:none;
            position: fixed;
            top: 0px;
            right:80px;
        }
    }
    li{
        .dropdown-item{
            line-height:19px;
        }
    }

}
.dropdown{
    .nav-label{
        margin-left:3px;
    }
    &:hover{
        .dropdown-menu{
            display:block;
        }
    }
    .dropdown-menu{
        margin-top:0;
        border-radius:0;
        border:none;
        a{
            font-size:11px;
            width: 100%;
            height: 100%;
            display: inline-block;
            padding-left:10px;
            text-align: center;
            &:hover{
                font-size:11px;
            }
        }
    }
}
.langue-hidedesktop{
    @include media-breakpoint-up(lg) {
        display:none;
    }
}
.languages-selector {
    color: $dark-grey;
    font-family: "Neutra2Text-Book";
    font-size: 11px;
    z-index: 100000;
    position:absolute;
    top:-50px;
    @include media-breakpoint-down(md) {
        position: fixed;
        top: 5px;
    }
    a{
        padding-left:3px;
        padding-right:3px;
        &:first-of-type{
            padding-left:0;
        }
        &:last-of-type{
            padding-right:0;
        }
    }
}

.navbar-toggler{
    margin-right: 20px;
    @include media-breakpoint-down(md) {
        margin-top:25px;
        border: 0px solid !important;
        color:white !important;
    }
}
@include media-breakpoint-down(md) {
    #navbarNav{
        .mb-0{
            margin-top:10px !important;
        }
        .py-2{
            padding-top:0rem !important;
            padding-bottom:0rem !important;
            a{
                padding-top: 0.6rem !important;
                padding-bottom: 0.6rem !important;
                display:inline-block;
            }
        }
    }
}

.ham-primary-color {
    border-color: $dark-grey;
    font-size:28px;
}

.menu-links {
    font-family: "Neutra2Text-Demi";
    font-size: 12px;
    color: $dark-grey;
    font-weight: 400;
    letter-spacing: 0px;

    a:hover, a:active {
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        font-weight: inherit;
    }
}

// bloc news sur acceuil
.all-news {
    padding: 0;
    //:first-child {
    //    padding-left: 0!important;
    //}
    //:last-child {
    //    padding-right: 0!important;
    //}
}

// page news
.news-title {
    color: black;
    font-family: "Neutra2Text-Book";
    font-size: 25px;
    margin-bottom: 19px;
}
.news-description {
    color: black;
    font-family: "Neutra2Text-Book";
    font-size: 15px;
}
.news-date {
    color: $dark-grey;
    font-family: "Neutra2Text-Book";
    font-weight: 900;
    font-size: 13px;
    margin-bottom: 19px;
    margin-top: 10px;
}

// General style
// Text style for paragraph are defined inside ckEditorStyles.css file
p {
    padding: 0px;
    //margin: 0px;
}

table {
    font-family: 'Neutra2Text-Book';
}

// Common button sizes
.btn-lg {
    font-size: 15px;
    font-weight: bold;
}

// Common form style

.form-control {
    background-color: rgba(145, 135, 123, 0.2);
    color: black;

    // Wierd things happened with this code below
    /*
    &:focus {
        color: black;
        background-color: rgba(145, 135, 123, 0.2);
        opacity: 100%;
    }
    */
}

.form-label {
    font-family: "Neutra2Text-Demi";
    color: $form-lbl-color;
    font-size: 13px;
    text-transform: uppercase;
}

// Menu
#cs_menu {
    background-color: white;
    margin: 0px;
    padding: 0px;
    @include media-breakpoint-down(md) {
        padding-bottom:15px !important;
    }
}

@include media-breakpoint-up(lg) {
    #cs_menu {
        height: 100px;
    }
  }


#cs-logo {
    position: relative;
    top: 35px;
    height: 125px;
    z-index: 1;
}
#cs-logo-smaller {
    position: relative;
    top: 15px;
    height: 90px;
    z-index: 1;
    @include media-breakpoint-down(md) {
        height:75px;
    }
}

.special-button {
    background-color: $dark-grey;
    color: white !important;

    padding: 25px;
    padding-bottom: 15px;
    padding-top: 15px;

    a, a:hover, a:active {
        background-color: inherit;
        color: inherit; 
    }
}

.common-text-style {
    font-family: "Neutra2Text-Book";
    font-size: 15px;
}

/*
.navbar {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 1;
    background-color: white;
    height: 100px;
}*/
/*
.navbar-nav li:hover > ul.dropdown-menu {
    display: block;
    font-size: 13px;
}
.dropdown-submenu {
    position:relative;
    font-size: 13px;
}
.dropdown-submenu>.dropdown-menu {
    top:0;
    left:100%;
    font-size: 13px;
}
*/

#inputPassword{
    width:100%;
}

div.hidden {
    display: none;
}

.text-align-right {
    text-align: right;
}
.height-icons{
    height: 30px;
}


.frame {
    overflow-y: scroll;
    background-color: rgba(145, 135, 123, 0.15);
    color: $dark-grey;
    border-radius: 5px;
    padding-left: 15px !important;
    padding-right: 15px !important;
    height: 300px;

    h1, h2, h3, h4, h5, h6 {
        color: inherit;
        margin-top: 24px;
        margin-bottom: 3px;
    }

    p {
        color: inherit;
    }
}

// NoUISlider
.noUI-connect {
    background: rgba(145, 135, 255, 0.50);
}

// Gift header text !
.circle {
    position: absolute;
    top: -63px;
    background: #981a1e;
    border-radius: 50%;
    width: 125px;
    height: 125px;
    left: 28%;
    text-align: center;
}

#header-gift-text {
    display: block;
    position: relative;
    top: 55%;
    color: white;
    font-size: 18px;
    font-family: "Neutra2Text-Bold";
    width: 125px;
    line-height: 18px;
}

.login-card, .news-card, .common-card, .registration-card, .global-card {
    background-color: white;
}

.login-card{
    min-height: 480px;
    padding-left:2rem !important;
    padding-right:2rem !important;
    h1{
        letter-spacing: 0px;
        font-size:37px;
    }
    h2{
        font-size:18px;
        letter-spacing: 0px;
        margin-bottom:5px;
    }
    .col-12{
        padding-left:0;
        padding-right:0;
        &:first-of-type{
            margin-bottom:40px;
        }
    }
    .form-control{
        background-color:#E8E6E4;
        border:0;   
        height:36px;
    }
    .login-buttons{
        display:flex;
        flex-direction: column;
        margin-top: 30px;
        button, a.special-button{
            border-radius:0;
            background-color: #91877B;
            border-color: #91877B;
            float:none;
            color:white;
            font-family: 'Neutra2Text-Book'; 
            font-size: 17px;
            font-weight: 400;
            letter-spacing: 0px;
            padding-top: 0.6rem;
            padding-bottom: 0.6rem;
            position: relative;
            padding-left: 55px;
            padding-right:35px;
            &::after{
                content:"\f061";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                position: absolute;
                left: 20px;
            }
            & + button, & + a.special-button{
                margin-top:10px;
            }
        }
        a{
            font-size: 13px;
            padding-top: 8px;
            font-family: 'Neutra2Text-Demi';
            letter-spacing: 0px;
        }
    }
}


.registration-card{
    padding-left:4rem !important;
    padding-right:4rem !important;
    @include media-breakpoint-down(md) {
        &.p-5{
            padding-top:2rem !important;
            padding-bottom:2rem !important;
        }
    }
    h1{
        letter-spacing: 0px;
        font-size:37px;
    }
    h2{
        font-size:18px;
        letter-spacing: 0px;
        margin-bottom:5px;
    }
    .col-12, .col-sm-12{
        padding-left:0;
        padding-right:0;
    }
    .col-6{
        padding-left:0;
        @include media-breakpoint-down(md) {
            max-width:100% !important;
        }
        &+.col-6{
            padding-right:0;
            padding-left:15px;
        }
    }
    .first-row{
        margin-top:40px;
    }
    .form-control{
        background-color:#E8E6E4;
        border:0;   
        height:36px;
    }
    button{
        border-radius:0;
        background-color: #91877B;
        border-color: #91877B;
        float:none;
        color:white;
        font-family: 'Neutra2Text-Book'; 
        font-size: 17px;
        font-weight: 400;
        letter-spacing: 0px;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        position: relative;
        padding-left: 55px;
        padding-right:35px;
        &::after{
            content:"\f061";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            position: absolute;
            left: 20px;
        }
    }
    #forgotten_password+.offset-6{
        margin-left:75%;
    }
    @include media-breakpoint-down(md) {
        .g-recaptcha{
            margin-top:20px;
        }
    }
}

.global-card{
    padding-left:4rem !important;
    padding-right:4rem !important;
    @include media-breakpoint-down(md) {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    h1{
        letter-spacing: 0px;
        font-size:37px;
        text-transform: uppercase;
    }
    h2{
        font-size:18px;
        letter-spacing: 0px;
        margin-bottom:5px;
    }
    label, legend{
        font-family: "Neutra2Text-Demi";
        color: #91877B;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 0px;
    }
    .col-12, .col-sm-12{
        padding-left:0;
        padding-right:0;
    }
    .col-6, .col-md-6{ 
        padding-left:0;
        &+.col-6, &+.col-md-6{
            padding-right:0;
        }
    }
    .col-lg-4{
        padding-left:0px;
        padding-right:15px;
        +.col-lg-4{
            padding-left:15px;
            +.col-lg-4{
                padding-right:0;
            }
        }
    }
    
    .first-row{
        margin-top:40px;
    }
    .form-control{
        background-color:#E8E6E4;
        border:0;   
        height:36px;
    }
    button, a.special-button{
        border-radius:0;
        background-color: #91877B;
        border-color: #91877B;
        float:none;
        color:white;
        font-family: 'Neutra2Text-Book'; 
        font-size: 17px;
        font-weight: 400;
        letter-spacing: 0px;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        position: relative;
        padding-left: 55px;
        padding-right:35px;
        &::after{
            content:"\f061";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            position: absolute;
            left: 20px;
            top: 9px;
        }
        &.arrowright{
            padding-right:45px;
            &::after{
                left:auto;
                right:15px;
                top: 9px;
            }
        }
        &.back::after{
            content: "\f060";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            position: absolute;
            left: 20px;
            top: 9px;
            color: white;
        }
    }
    .float-right{
        button{
            float:right;
        }
    }
    .input-group-append, .input-group-prepend{
        .input-group-text{
            border:0;
            background:#91877B;
            color:white;
            font-family: 'Neutra2Text-Demi';
        }
    }
    #forgotten_password+.offset-6{
        margin-left:75%;
    }
    #gift_card_format{
        .form-check{
            label{
                color: black;
                font-family: 'Neutra2Text-Book';
                text-transform: unset;
            }
            input[type="radio"]{
                margin-top:8px;
            }
        }
    }
    input{
        font-family:'Neutra2Text-Book';
        letter-spacing: 0px;
    }
    #bookableOptions{
        font-family:'Neutra2Text-Book';
        letter-spacing: 0px;
    }
    table{
        th, td{
            padding-left:0;
            padding-right:0;
        }
    }
}

.recap-row{
    padding-left:15px;
    padding-right:15px;
    table{
        th, td{
            padding-left:0;
            padding-right:0;
        }
    }
}

.homepage-news-card{
    .news-image{
        background-size:cover;
        height:250px;
        //width:480px;
    }
    .news-text{
        .col-12{
            padding-left:0;
            padding-right:0;
            .news-title{
                margin-bottom:0.5rem;
                margin-top:1.5rem;
                font-size: 18px;
                letter-spacing: 0px;
                text-transform: uppercase;
            }
            .special-button{
                width:100%;
                border-radius:0;
                padding:0;
                font-family: 'Neutra2Text-Book';
                font-size:17px;
                font-weight: 400;
                letter-spacing: 0px;
                a{
                    padding:15px;
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                }
            }
        }
    }
}

.news-card{
    .title-news-section{
        .news-title{
            font-size: 22px;
            letter-spacing: 0px;
            text-transform: uppercase;
            margin-bottom: 1rem;
        }
    }
    .image-news-section{
        padding-left:15px;
        padding-right:15px;
        @include media-breakpoint-down(md) {
            padding-left:0px;
            padding-right:0px;
        }
        > .col-12{
            background-size:cover;
            height:250px;
        }
    }
    .content-news-section{
        .news-date{
            margin-bottom: 0.7rem;
            margin-top: 0.7rem;
            font-family: "Neutra2Text-Demi";
            font-weight:400;
        }
        .news-description{
            margin-bottom:2rem;
            letter-spacing:0px;
            font-size:14px;
            height:80px;
        }
        .news-button{
            .special-button{
                width:60%;
                border-radius:0;
                padding:0;
                font-family: 'Neutra2Text-Book';
                font-size:17px;
                font-weight: 400;
                letter-spacing: 0px;
                a{
                    padding:10px;
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                }
            }
        }
    }
}

.standard-card {
    background-color: white;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
    //margin: 0px !important;
}

.circuit-card-nm0 {
    margin: 0 -15px!important;
    @include media-breakpoint-down(md) {
        margin: 0 !important;
    }
}
.circuit-card-p0 {
    padding: 0px;
}

.circuit-card {
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition:0.5s all;
    &:hover{
        transform:scale(1.02);
        box-shadow: 0px 0px 20px -5px #91877b;
    }
    h2, .circuit-card-title {
        color: $dark-grey;
        font-family: "Neutra2Text-Book";
        font-size: 13px;
        margin: 0px;
        padding: 0px;
        text-transform: uppercase;
        font-weight: 900;
        line-height: 33px;
    }

    h3, .circuit-card-name-title {
        color: black;
        font-family: "Neutra2Text-Book";
        font-size: 26px;
        margin: 0px;
        padding: 0px;
        text-transform: uppercase;
        line-height: 33px;
    }

    .shop-icon{
        width:28px;
        margin-bottom: 15px;
    }
}

.light-grey-card {
    color: black;
    background-color: $lighter-grey;
    background-color: #f4f3f2;
    padding: 50px 50px 25px;
    margin-bottom: 15px;
    margin-top: 50px;
    min-height:625px;

    h1 {
        color: inherit;
        font-family: "Neutra2Text-Book";
        font-size: 30px;
        text-transform: uppercase;
    }

    p {
        color: inherit;
        font-size: 15px;
        letter-spacing: 0px;
    }

    a, a:hover, a:active {
        color: white;
        text-decoration: none;
    }
}

.mid-grey-card {
    color: black;
    background-color: $light-grey;
    background-color: #e8e6e3;
    padding: 60px 50px 30px;
    margin-bottom: 15px;
    min-height: 700px;

    h1 {
        color: inherit;
        font-family: "Neutra2Text-Book";
        font-size: 30px;
        text-transform: uppercase;
    }

    p {
        color: inherit;
        font-size: 15px;
        letter-spacing: 0px;
    }
    button{
        margin-top: 1rem !important;
    }

    a, a:hover, a:active {
        color: white;
        text-decoration: none;
    }
}

.dark-grey-card {
    color: black;
    background-color: $darker-light-grey;
    background-color: #d6d2ce;
    padding: 50px 50px 15px;
    margin-bottom: 15px;
    margin-top: 50px;
    min-height: 625px;

    h1 {
        color: inherit;
        font-family: "Neutra2Text-Book";
        font-size: 30px;
        text-transform: uppercase;
    }

    p {
        color: inherit;
        font-size: 15px;
        letter-spacing: 0px;
    }

    a, a:hover, a:active {
        color: white;
        text-decoration: none;
    }
}

.flex{
    display:flex;
}

.dark-grey-card, .light-grey-card, .mid-grey-card{
    button{
        border-radius: 0;
        width: 100%;
        font-family: 'Neutra2Text-Book';
        font-size:17px;
        font-weight: 400;
        letter-spacing: 0px;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        position:relative;
        padding-left:35px;
        i{
            position: absolute;
            left: 25px;
            top: 0;
            bottom: 1px;
            display: flex;
            align-items: center;
        }
    }
}

@include media-breakpoint-up(lg)
{
    .card-over-content-above-1 {
        z-index: 1000;
        margin-top: -100px !important;
        position:relative;
    }
    
    .card-over-content-above-2 {
        z-index: 1000;
        margin-top: -150px !important;
        position:relative;
    }
    
    .card-over-content-above-3 {
        z-index: 1000;
        margin-top: -200px !important;
        position:relative;
    }

    .card-over-content-above-circuit {
        z-index: 1000;
        margin-top: -220px !important;
        position:relative;
    }
}

.dark-grey-banner {
    background-color: $dark-grey;
    color: white;
    font-family: "Neutra2Text-Book";
    padding-top:40px;
    padding-bottom:40px;
    h1, .banner-title {
        color: inherit;
        font-family: inherit;
        text-transform: uppercase;
        letter-spacing: 0px;
        line-height: initial!important;
        margin-bottom: 0;
        padding-left:10%;
        padding-right:10%;
        font-size: 30px;
        @include media-breakpoint-up(lg) {
            padding-left: 25%;
            padding-right: 25%;
        }
    }
}

.homepage-news{
    margin-top: 60px;
    margin-bottom: 10px;
    h2{
        color:$h3-color;
        text-transform: uppercase;
        font-size: 24px;
        letter-spacing: 0px;
        font-weight: 500;
    }
}


.common-white-card {
    background-color: white;
    width: 100%;
}

.no-padding-h {
    padding-left: 0px;
    padding-right: 0px;
}

.spacer {
    height: 25px;
}

.image-banner {
    height: 50px;
    width: 100%;
    position: relative;
    margin-top: -50px;
    background-color:#91877b52;
    a {
        position: relative;
        top: 0;
        color: white;
        text-align: center;

        font-family: "Neutra2Text-Demi";
        color: white;
        font-size: 20px;
        z-index: 3;

        height:100%;
        width:100%;
        display:flex;
        justify-content: center;
        align-items:center;
        text-decoration:none;
        span{
            font-family: 'Neutra2Text-Book';
            font-size: 17px;
            font-weight: 400;
            letter-spacing: 0px;
        }
    }
}

.image-banner:hover {
    span{
        text-decoration: underline;
    }
} 

.image-banner::after {
    content:'';
    position:absolute;
    top:0; 
    bottom:0;
    left:0;
    right:0;
    background-color:$dark-grey;
    opacity:0.82 !important;
    z-index:2;
}

.secondary-information {
    h4 {
        color: $dark-grey;
        font-family: "Neutra2Text-Book";
        font-size: 13px;
        margin: 0px;
        padding: 0px;
        text-transform: uppercase;
    }

    p {
        color: black;
        font-family: "Neutra2Text-Book";
        font-size: 13px;
        margin: 0px;
        padding: 0px;
    }
}

.spinner {
    color: $dark-grey;
}

p {
    font-size: 15px;
    color: black;
    font-family: 'Neutra2Text-Book';
}

main {
    margin-top: 0px;
    padding-top: 0px !important;
}

.infosContent{
    background-color:white;
    padding: 3%;
    h4{
        font-weight:400 !important;
        font-family: "Neutra2Text-Demi";
        text-transform: uppercase;
        letter-spacing: 0px;
    }
    .published-date-p{
        font-family: "Neutra2Text-Book";
    }
    .content-news-text, .content-news-text p{
        font-family: "Neutra2Text-Book";
        font-size:14px;
        letter-spacing: 0px;
    }
    .row + .row{
        margin-top:40px;
    }
}

.col-no-padding{
    padding: 0!important;
}

#circuit_search{
    h2.circuit-title{
        margin-bottom:20px;
    }
    .filtres{
        form{
            background-color:white;
            padding:30px 45px 20px;
            .form-label{
                letter-spacing: 0px;
            }
            .filtre-slider{
                margin-bottom:50px;
                &:last-of-type{
                    margin-bottom:0;
                }
                label{
                    margin-bottom:20px;
                }
                .noUi-horizontal{
                    border: 1px solid $h1-color;
                    height:8px;
                    border-radius:0;
                    .noUi-connect{
                        background:$h1-color;
                    }
                    .noUi-handle{
                        top:-5px;
                        height:16px;
                        width:19px;
                        border: 1px solid $h1-color;
                        border-radius: 0;
                        background: $h1-color;
                        box-shadow: none;
                        &::after, &::before{
                            content:none;
                        }
                    }
                }
            }
            .accessibility, .region{
                .form-check{
                    padding-top:1rem;
                    &:first-of-type{
                        padding-top:0;
                    }
                    .form-check-input{
                        margin-top:0.4rem;
                        visibility:hidden;
                        &:checked{
                            +.form-check-label::after{
                                content:'';
                                position:absolute;
                                left:-18px;
                                top:0;
                                bottom:2px;
                                margin:auto;
                                width:8px;
                                height:8px;
                                background-color: #91877B;
                            }
                        }
                    }
                    .form-check-label{
                        color:black;
                        font-family: 'Neutra2Text-Book';
                        text-transform: capitalize;
                        position:relative;
                        &::before{
                            content:'';
                            position:absolute;
                            left:-20px;
                            top:0;
                            bottom:2px;
                            margin:auto;
                            width:12px;
                            height:12px;
                            border:1px solid black;
                        }
                    }
                }
                
            }
            .form-submit-button{
                .form-group{
                    .col-sm-12{
                        margin-left:30px;
                    }
                }
                button{
                    border-radius:0;
                    background-color: #91877B;
                    border-color: #91877B;
                    font-family: 'Neutra2Text-Book';
                    font-size: 17px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    padding-top: 0.6rem;
                    padding-bottom: 0.6rem;
                    position: relative;
                    padding-left: 55px;
                    padding-right:35px;
                    &::after{
                        content:"\f061";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        position: absolute;
                        left: 20px;
                    }
                }
            }
        }
    }
    .circuits-liste{
        .common-white-card{
            padding:30px 45px 20px;
            .table{
                thead{
                    border-bottom:1px solid $h1-color;
                    th{
                        border-top:0;
                        font-family: 'Neutra2Text-Demi';
                        font-size: 15px;
                        font-weight: 500;
                        letter-spacing: 0px;
                        border-bottom:0;
                    }
                }
                tbody{
                    tr{
                        border-bottom:1px solid $h1-color;
                        &:last-of-type{
                            border-bottom: 0;
                        }
                        td{
                            font-size:15px;
                            letter-spacing: 0px;
                            &:first-of-type{
                                font-family: 'Neutra2Text-Demi';
                                text-transform: uppercase;
                                a{
                                    color:black;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.contact-section{
    .common-card{
        padding:30px 45px 20px;
        @include media-breakpoint-down(md) {
            padding:30px 15px 20px;
            .col-lg-8{
                padding-left:0;
                padding-right:0;
            }
        }
        .row .row{
            .col-12{
                padding-left:0;
                padding-right:0;
            }
            .col-6{
                padding-left:0;
                padding-right:8px;
                @include media-breakpoint-down(md) {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                    width:100%;
                    max-width: 100% !important;
                }
                &+.col-6{
                    padding-right:0;
                    padding-left:8px;
                }
            }
        }
        .form-label, h3, p, input{
            letter-spacing: 0px;
        }
        #selector-content{
            h3{
                font-family: "Neutra2Text-Demi";
            }
        }
        .form-control{
            background-color:#E8E6E4;
            border:0;   
            height:36px;
        }
        textarea.form-control{
            height:77px;
        }
        input{
            font-family: 'Neutra2Text-Book';
            font-size:15px;
        }
        select{
            &.form-label{
                text-transform: capitalize;
                font-family: 'Neutra2Text-Book';
                color:black;
                padding-left: 6px;
                padding-top: 10px;
            }
            option{
                font-family: 'Neutra2Text-Book'!important;
            }
        }
        .form-group{
            .col-sm-12{
                margin-left:15px;
                margin-top:5px;
                margin-bottom:10px;
                button{
                    border-radius:0;
                    background-color: #91877B;
                    border-color: #91877B;
                    float:right;
                    color:white;
                    font-family: 'Neutra2Text-Book';
                    font-size: 17px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    padding-top: 0.6rem;
                    padding-bottom: 0.6rem;
                    position: relative;
                    padding-left: 55px;
                    padding-right:35px;
                    &::after{
                        content:"\f061";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        position: absolute;
                        left: 20px;
                    }
                }
            }
        }
        
    }
}

#circuit-show{
    margin-top:-70px;

    .row-fluid{
        > .col-12{
            padding-left:0;
            padding-right:0;
        }
    }
    > .row-fluid > .row > .standard-card + .col-12{
        padding-right:0;
    }
    .ckEditorStyle p, .cke_editable p, p {
        letter-spacing:0;
    }
    .ckEditorStyle{
        h1{
            font-family: "Neutra2Text-Book";
            text-transform: uppercase;
            letter-spacing:0;
            margin-bottom:1rem;
        }
        p{
            margin-bottom:1rem;
            font-size:15px;
            letter-spacing: 0;
            &:last-of-type{
                margin-bottom:0;
            }
        }
        /* replacing the default list bullet with a colorfull one */
        ul {
            margin: 0 0 0 18px;
            padding: 0!important;

            /* ::marker is still not fully supported on every browser but should be fine on major ones (firefox, chrome, edge ...) some versions for smarphone devices might not render properly  */
            li::marker {
                color: #91877B;
                font-size: 20px;
            }
            ul {
                padding: 1em!important;
            }
        }
    }
    .standard-card{
        padding-right:70px;
        padding-left:70px;
        padding-top:40px;
    }
    .social-link{
        padding-left:0;
        padding-right:0;
        .col-12{
            padding-left:0;
            padding-right:0;
        }
        h4{
            font-family:"Neutra2Text-Demi";
            text-transform: uppercase;
            letter-spacing: 0px;
            font-size:15px;
        }
        a{
            padding-left:10px;
            &:first-of-type{
                padding-left:0;
            }
        }
    }
    .secondary-information{
        @include media-breakpoint-down(md) {
            padding-left:30px;
            padding-right:30px;
        }
        >div:first-of-type{
            a{
                position:relative;
                display:flex;
                align-items: center;
                &:hover{
                    text-decoration: none;
                }
                &::after {
                    content: "\f061";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    position: absolute;
                    left: 20px;
                    color: white;
                }
                &.no-arrow::after{
                    content:"";
                }
                .btn-primary{
                    border-radius:0;
                    background-color: $dark-grey !important;
                    border-color: $dark-grey !important;
                    width:100%;
                    cursor: pointer;
                    color:white;
                    font-family: 'Neutra2Text-Book';
                    font-size: 17px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    padding-top: 0.6rem;
                    padding-bottom: 0.6rem;
                    position: relative;
                    padding-left: 55px;
                    padding-right:35px;
                }
            }
        }
        
        .additional-info{
            margin-bottom:1.5rem;
            &:last-of-type{
                margin-bottom:0;
            }
            .col-12{
                padding-left:0;
                padding-right:0;
            }
            p{
                letter-spacing: 0px;
                font-size:13px;
                padding: 0;
                margin: 0;
            }
            h4{
                font-family: 'Neutra2Text-Demi';
                letter-spacing: 0px;
                font-size:13px;
            }
        }
    }
    .postes-circuit{
        margin-top:30px;
        .col-12{
            padding-left:0;
            padding-right:0;
        }
        .secondary-information{
            p{
                font-size:13px;
                letter-spacing: 0px;
            }
        }
    }
    .googlemap{
        margin-top:10px;
        .col-12{
            padding-left:0;
            padding-right:0;
        }
    }
}

.carousel-item{
    background-size:cover !important;
    height:500px;
}

.other-circuits{
    margin-top:70px;
    h2{
      color: $h3-color;
      font-family: "Neutra2Text-Book";
      font-size: 30px;
    }
}

#about-us-page{
    div.first-content{
        margin-top:10px;
        h1{
            font-size:26px;
            margin-top:5px;
            margin-bottom:5px;
        }
        p{
            color: black;
            font-family: "Neutra2Text-Book";
            font-size: 20px;
            letter-spacing: 0px;
            line-height: 35px !important;
            strong, a{
                color: #91877B;
                font-family: "Neutra2Text-Demi";
                font-weight:400;
            }
        }
    }
    .nos-circuits{
        margin-bottom:6rem !important;
        .circuits-button{
            display:flex;
            flex-direction:column;
            justify-content: center !important;
            .special-button{
                width:60%;
                border-radius:0;
                padding:0;
                font-family: 'Neutra2Text-Book';
                font-size:17px;
                font-weight: 400;
                letter-spacing: 0px;
                margin-top:20px;
                margin-left:0 !important;
                a{
                    padding:10px;
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                }
            }
            p{
                letter-spacing: 0px;
            }
        }
    }
    .nos-partenaires{
        margin-bottom:4rem !important;
        h2{
            color: black;
            font-family: "Neutra2Text-Book";
            font-size: 30px;
            text-transform: uppercase;
            letter-spacing: 0px;
            margin-bottom:20px;
        }
        p{
            letter-spacing: 0px;
        }
        .separateur{
            background:#91877B;
            height:1px;
            width:100%;
            &:last-of-type{
                display:none;
            }
        }
        .liste-partenaire{
            display:flex;
            flex-direction:column;
            justify-content: center !important;
            .col-md-6{
                display:flex;
                justify-content: center;
                align-items: center;
                @include media-breakpoint-down(md) {
                    width:50%;
                }
            }
            .logo_partenaire{
                max-width:90%;
                filter: grayscale(100%);
                transition:0.5s all;
                &:hover{
                    filter: grayscale(0%);
                }
            }
        }
    }
    .nos-circuits, .nos-partenaires{
        padding-top:20px;
        padding-bottom:20px;
        background:white;
    }
}


#accueil{
    margin-top:-70px;
    #roundCarousel{
        .carousel-item{
            height:640px;
            background-size:cover !important;
        }
    }
    .three-cards{
        .content-card{
            p{
                margin-top:14px;
            }
            h1, h2{
                margin-bottom:20px;
                span{
                    font-family: 'Neutra2Text-Book';
                }
            }
        }
    }
    .editable-content{
        h1{
            font-size:28px;
        }
        .special-button{
            width: 60%;
            border-radius: 0;
            padding: 0;
            font-family: 'Neutra2Text-Book';
            font-size: 17px;
            font-weight: 400;
            letter-spacing: 0px;
            margin-top: 20px;
            margin-left: 0 !important;
            a{
                padding: 10px;
                width: 100%;
                height: 100%;
                display: inline-block;
            }
        }
    }
    
}

#youtube-video-iframe {
    display:block;
    width: 478px;
    height: 270px;
    margin-top: 190px;
}
#circuit-show {
    #youtube-video-iframe {
        display:block;
        width: 599px;
        height: 338px;
        margin-top: 0;
    }
}

.oembed-provider-youtube iframe {
    width: 455px;
    height: 256px;
}

.visitor.table{
    th{
        text-transform: uppercase;
        font-family: 'Neutra2Text-Demi';
        letter-spacing: 0px;
        font-size:13px;
        vertical-align: middle;
        border-top:0;
    }
    td, th{
        border-top:0px solid;
    }
}

.table{
    tr:first-of-type{
        th{
            border-top:0;
        }
    }
    th{
        text-transform: uppercase;
        font-family: 'Neutra2Text-Demi';
        letter-spacing: 0px;
        font-size:13px;
        vertical-align: middle;
    }
}

.common-bullet-list {
    padding-left: 25px;
}

.common-bullet-point {
    list-style: none;
}

.common-bullet-point::before {
    content: "\2022";
    color: #91877B;
    display: inline-block;
    width: 1em;
    margin-left: -0.9em;
    font-size: 25px;
}

.position-corner {
    position: absolute;
    top:-30px;right:-30px;
}
.mouse-over-toggle{
    display: inline-block;
}
.mouse-over-display {
    z-index: 1;
    visibility: hidden;
    position: absolute;
    background-color: #91877b;
    font-size: 15px;
    color: #fff;
    text-align: center;
    font-family: "Neutra2Text-Book", sans-serif;
    width: 300px;
    min-height: 100px;
    right: 0;
    margin: 8px 5px 0 0;
    padding: 10px 20px;
    border-radius: 12px 0 12px 12px;
}
.mouse-over-display:after{
    content:'';
    position: absolute;
    top:-20px;right:0;
    width:20px;
    height:20px;
    border-radius: 0 0 60% 0;
    box-shadow: 0 13px 0 0 #91877b;
}

.mouse-over-toggle:hover .mouse-over-display {
    visibility: visible;
}

#imageTag .loaded {
    padding:0;
    margin:0;
}
.no-pad {
    padding:0;
}
.no-margin {
    margin:0;
}
.flex-width-80 {
    width:100%;
    position: relative;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}

#about-us-page .first-content p {
    line-height: 30px!important;
}

/* bouton timeslots */

#bookableOptions {
    .btn-primary {
        min-width: 160px!important;
        opacity: 0.8;
        @include media-breakpoint-down(md) {
            min-width: auto !important;
            width: calc(50% - 8px);
            font-size: 16px;
            padding-left: 47px;
            padding-right: 30px;
            
        }
        &.passed{
            opacity:0.3;
            cursor:default;
        }
		&.active{
			opacity:1;
		}
    }
}
button.timeslots {
    background-color: #9f9589 !important;
}
button.timeslots.active{
    background-color: #7d7468 !important;
}

.validate{
    justify-content: flex-end;
    display: flex;
}

/* user checkbox acces mobile  */
#timeslot{
    @include media-breakpoint-down(md) {
        padding-left:20px !important;
        padding-right:20px !important;
        padding-bottom:20px !important;
        padding-top:20px !important;
        margin-top:0px !important;
        .pr-3{
            padding-right:0 !important;
        }
        .special-button{
            max-width: 50%;
            flex: 0 0 50%;
        }
    }
    .special-button{
        margin-top:30px;
    }
}

#chooseadress{
    padding-bottom:2rem !important;
    @include media-breakpoint-down(md) {
        padding-left:20px !important;
        padding-right:20px !important;
        padding-bottom:20px !important;
        padding-top:20px !important;
        .form-group{
            margin-bottom:0;
        }
        .mt-5{
            margin-top:2rem !important;
        }
        .mb-5{
            margin-bottom:2rem !important;
        }
        
    }
    .btn:hover{
        color:white;
    }
    .arrowright{
        padding-left:35px;
    }
    #selector-content{
        margin-bottom:30px;
        p{
            margin-bottom:0;
        }
        .form-label{
            margin-bottom:0.5rem;
        }
        @include media-breakpoint-down(md) {
            & > div > .row > div + div > .form-label{
                margin-top:1rem;
            }
        }
    }
    .my-3{
        margin-top:0 !important;
        margin-bottom:0 !important;
    }
}
.global-card.button-bottom-adresse{
    padding-left: 4rem !important;
    padding-right: 4rem !important;
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
    @include media-breakpoint-down(md) {
        padding-left:20px !important;
        padding-right:20px !important;
        padding-bottom:20px !important;
        padding-top:20px !important;
    }
    .flexend{
        display:flex;
        justify-content: flex-end;
        button{
            @include media-breakpoint-down(md) {
                width:calc(50% - 10px);
            }
        }
    }
    .form-group{
        margin-bottom:0;
        .col-sm-12 + .col-sm-12{
            display:flex;
            justify-content: flex-end;
            button{
                @include media-breakpoint-down(md) {
                    /*width:calc(50% - 10px);*/
                }
            }
        }
        
    }
    
    .btn:hover{
        color:white;
    }
    .arrowright{
        padding-left:35px;
    }
}

#visitortemplate{
    @include media-breakpoint-down(md) {
        .global-card{
            padding-left:20px !important;
            padding-right:20px !important;
            padding-bottom:0px !important;
            padding-top:20px !important;
            .visitor{
                .col-6{
                    max-width: 100% !important;
                    padding-right:0 !important;
                }
                .form-check-label{
                    margin-top:4px;
                }
            }  
        }
        .px-3{
            padding-left:0 !important;
            padding-right:0 !important;
            .p-5{
                padding-right:10px !important;
                button{
                    width:100%;
                }
                & + .p-5{
                    padding-left:10px !important;
                    padding-right:20px !important;
                }
            }
        } 
    }
} 
.additionnalPhone{
    padding-left: 4rem !important;
    padding-right: 4rem !important;
    padding-top:3rem !important;
    padding-bottom:3rem !important;
    @include media-breakpoint-down(md) {
        padding-left:20px !important;
        padding-right:20px !important;
        padding-bottom:20px !important;
        padding-top:20px !important;
    }
}

#paniertemplate{
    @include media-breakpoint-down(md) {
        &.global-card{
            padding-left:20px !important;
            padding-right:20px !important;
            padding-bottom:0px !important;
            padding-top:20px !important;
            .px-5{
                padding-left:0 !important;
                padding-right:0 !important;
                .col-4{
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding-left:0;
                    padding-right:0;
                }
            }
            table th .text-right{
                padding-left:5px !important;
            }
            table{
                width:100% !important;
                th, td{
                    font-weight:400;
                    &.final-price{
                        font-size:13px;
                    }
                }
            } 
        }       
    }
} 
#recaptemplate{
    @include media-breakpoint-down(md) {
        .global-card{
            padding-left:20px !important;
            padding-right:20px !important;
            padding-bottom:0px !important;
            padding-top:20px !important;
            .px-5{
                padding-left:0 !important;
                padding-right:0 !important;
                .col-4{
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding-left:0;
                    padding-right:0;
                }
            }
            table th .text-right{
                padding-left:5px !important;
            }
            table{
                width:100% !important;
                th, td{
                    font-weight:400;
                    &.final-price{
                        font-size:13px;
                    }
                }
            } 
        }
        .common-white-card{
            padding-left:20px !important;
            padding-right:20px !important;
            padding-bottom:20px !important;
            padding-top:20px !important;
            .px-5, .recap-row{
                padding-left:0 !important;
                padding-right:0 !important;
            }
            .adressefacturation{
                p{
                    margin-bottom:0;
                }
                .form-label{
                    margin-bottom:0.5rem;
                }
                & > .row > div + div > .form-label{
                    margin-top:1rem;
                }
            }
        }
        .frame{
            h1{
                font-size:25px;
            }
        }       
    }
    .adressefacturation{
        .col-12{
            padding-left:0 !important;
            padding-right:0 !important;
            p{
                margin-bottom:0;
            }
            .form-label{
                margin-bottom:0.5rem;
            }
        }
    }
}
.global-card.button-bottom{
    @include media-breakpoint-down(md) {
        padding-left:20px !important;
        padding-right:20px !important;
        padding-bottom:20px !important;
        padding-top:20px !important;
        margin-top:0 !important;
        .py-5{
            padding-top:0 !important;
            padding-bottom:0 !important;
            button{
                width:100%; 
            }
        }   
    }
    .arrowright{
        padding-left:35px;
    }
}
.summup_payment{
    .global-card{
        @include media-breakpoint-down(md) {
            padding-left:20px !important;
            padding-right:20px !important;
            padding-bottom:20px !important;
            padding-top:20px !important;
            margin-top:0 !important;
        }
    }
}


#mobileAccessWrapper{
    margin-bottom: 15px!important;
    @include media-breakpoint-down(md) {
        #mobileAccess{
            margin-bottom:10px;
            .col-3{
                display:flex;
                align-items:center;
            }
            img{
                height:76px;
                padding-top:0;
            }
            .option-title{
                margin: 10px 10px 10px 20px;
                font-size:15px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .option-detail{
                    margin:0px;
                }
            }
        }
        #badgeAccess{
            margin-bottom:10px;
            .col-3{
                display:flex;
                align-items:center;
            }
            img{
                height:66px;
                padding-top:0;
            }
            .option-title{
                margin: 10px 10px 10px 20px;
                font-size:15px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .option-detail{
                    margin:0px;
                }
            }
        }
    }
}
#mobileAccess, #badgeAccess {
    cursor: pointer;
    background-color: $lighter-grey;
    box-shadow: 0 3px 6px #00000029;
    color: $dark-grey;
    height:140px;

    img {
        height: 116px;
        padding-top: 20px;
    }

    .option-title {
        font-weight: bold;
        margin: 30px 10px 10px 20px;
        .option-detail {
            font-weight: normal;
            font-size: 13px;
            margin: 10px 0;
        }
    }
}
.mobile-access-title {
    color: $dark-grey;
    text-transform: uppercase;
}
.method-selected {
    background-color: $dark-grey!important;
    color: white!important;
    box-shadow: inset 0 3px 6px #00000029!important;
}

.account-base-content{
    .col-2{
        padding-left:0;
        padding-right:0;
    }
    .table tr th, .table tr td{
        padding-left:0;
    }
}

.page-history .historical-image img{
    @include media-breakpoint-down(md) {
        width:100%;
    }
}

.circuit-card .my-3{
    @include media-breakpoint-down(md) {
        margin-bottom:0 !important;
    }
}

#partners-area .col-md-5{
    @include media-breakpoint-down(md) {
        padding-left:0 !important;
        padding-right:0 !important;
    }
}

.d-none.d-lg-block svg{
    .d tspan{
        fill:white;
    }
}
@include media-breakpoint-down(md) {
    .alert-warning{
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    #address_selector_submit{
        width:140px !important;
    }
}

@include media-breakpoint-down(md) {
    #circuit-show .position-corner{
        top:-40px;
        right:-40px;
    }
    #circuit-show .secondary-information > .col-12.my-3{
        margin-top: 3rem !important;
    }
}


.mouse-over-display p{
    color:white;
}

#buy-button-mobile{
    display:none;
    @include media-breakpoint-down(md) {
        display:block;
    }
    input{
        border-radius: 0; 
        background-color: #91877B !important; 
        border-color: #91877B !important; 
        width: 250px !important; 
        cursor: pointer; 
        color: white; 
        font-family: 'Neutra2Text-Book'; 
        font-size: 17px; 
        font-weight: 400; 
        letter-spacing: 0px; 
        padding-top: 0.6rem; 
        padding-bottom: 0.6rem; 
        position: relative; 
        padding-left: 55px;
        padding-right: 35px;
    }
    a{
        position:relative;
        display: flex;
        align-items: center;
        &::after{
            content: "\f061";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            position: absolute;
            left: 20px;
            color: white;
        }
    }
}