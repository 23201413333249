
/* FONT DEF. FOR CKEDITOR*/
@font-face {
    font-family: 'Neutra2Text-Demi';
    src:    url('/build/fonts/Neutra2Text-Demi.otf');
}
@font-face {
    font-family: 'Neutra2Text-Bold';
    src:    url('/build/fonts/Neutra2Text-Bold.otf');
}
@font-face {
    font-family: 'Neutra2Text-Book';
    src:    url('/build/fonts/Neutra2Text-Book.otf');
}

.ckEditorStyle h1, .cke_editable h1 {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0px;
    font-weight: 500;
    color: black;
}

.ckEditorStyle h2, .cke_editable h2 {
    font-size: 22px;
    line-height: 1.5;
    letter-spacing: 0px;
    font-weight: 500;
    color: black;
}

.ckEditorStyle h3, .cke_editable h3 {
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: 0px;
    font-weight: 500;
    color: black;
}

.ckEditorStyle p, .cke_editable p, p {
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0px;
    font-weight: 400;
    font-family: "Neutra2Text-Book";
    margin-top: 15px;
    margin-bottom: 15px;
}

.ck-standard-text {
    font-family: "Neutra2Text-Book";
    color: black;
}

.ck-title {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0px;
    font-weight: 500;
    font-family: "Neutra2Text-Book";
    color: black;
    text-transform: uppercase;
}


.ck-subtitle1 {
    font-size: 22px;
    line-height: 1.5;
    letter-spacing: 0px;
    font-weight: 500;
    font-family: "Neutra2Text-Book";
    color: #91877b;
}

.ck-subtitle2 {
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: 0px;
    font-weight: 500;
    font-family: "Neutra2Text-Book";
    color: #91877b;
}

.ckEditorStyle ul, .ckEditorStyle li, .ckEditorStyle li>p,
.cke_editable ul, .cke_editable li, .cke_editable li>p {
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0px;
    font-weight: 400;
    font-family: "Neutra2Text-Book";
    margin: 0px;
}

/*-----------------------------------------------------
OLD STYLES
-----------------------------------------------------*/

/*
.ckEditorStyle h1{
    color: black;
    font-size: 45px;
}

.ckEditorStyle h2{
    color: black;
    font-size: 20px;
}

.ckEditorStyle h3{
    color: black;
    font-size: 12px;
}

.ckEditorStyle .h1-gray{
    color: #91877B;
    font-size: 45px;
    font-family: "Neutra2Text-Bold";
}
.ckEditorStyle .h2-gray{
    color: #91877B;
    font-size: 20px;
    font-family: "Neutra2Text-Demi";
}
.ckEditorStyle .h3-gray{
    color: #91877B;
    font-size: 14px;
    font-family: "Neutra2Text-Demi";
}

.ckEditorStyle .h1-black{
    color: black;
    font-size: 45px;
    font-family: "Neutra2Text-Bold";
}

.ckEditorStyle .h2-black{
    color: black;
    font-size: 20px;
    font-family: "Neutra2Text-Demi";
}

.ckEditorStyle .h3-black{
    color: black;
    font-size: 14px;
    font-family: "Neutra2Text-Demi";
}

.ckEditorStyle p{
    color: black;
    font-weight: normal;
    font-size: 15px;
    letter-spacing: 0.6px;
    font-family: "Neutra2Text-Book";
    padding: 0px;
    margin: 0px;
}

.ckEditorStyle span{
    color: black;
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.6px;
    font-family: "Neutra2Text-Book";
    padding: 0px;
    margin: 0px;
}

.embeddedContent{
    display: block;
    width: 100%;
}

.ckEditorStyle img {
    max-width: 100%;
}
*/