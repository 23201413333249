
/* affichage tablette, nécéssaire car il y à un intermédiaire en dur */
@media (max-width: 990px) {

  .carousel-item{
    background-size:cover !important;
    height:220px;
  }

  #circuit-show > .row-fluid > .row > .standard-card + .col-12 {
    padding-left: 0;
  }

  #accueil #roundCarousel .carousel-item {
    height:320px!important;
  }

  #circuit-show{
    margin-top:-70px;
  }
  /* circuit */
  .circuit-title {
    font-size: 30px!important;
    font-weight: bold!important;
    line-height: 30px!important;
    margin-top: 20px;
  }
  /* circuit yt video */
  #youtube-video-iframe {
    display:block;
    width: 100%;
    height: 100%;
    min-width: 380px;
    min-height: 270px;
    margin-top: 0px;
    padding: 21px 21px 0 21px;
  }

  #about-us-page {
    .first-content {
      p {
        font-size: 20px;
      }
    }
  }

  #who-anchor {
    top: -50px;
  }

  #about-us-page .nos-circuits, #about-us-page .nos-partenaires {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 15px!important;;
    margin-bottom: 15px!important;;
  }

  #inputPassword{
    width:100%;
  }

  .login-card {
    margin-bottom: 50px;
  }
}

/* affichage mobile */
@media (max-width: 768px) {

  #cs_menu {
    padding-right:1px!important;
  }

  .langue-hidedesktop .languages-selector {
    right: 22px;
  }

  .ck-title {
    margin-top: 20px;
  }

  #accueil #roundCarousel .carousel-item {
    height:280px!important;
  }

  h6, .slogans-titles {
    font-size: 7vw;
  }

  .light-grey-card, .mid-grey-card, .dark-grey-card {
    padding:15px;
    margin-bottom: 15px;
    min-height: auto;
    margin-top: 50px;
  }

  /* circuit cards */
  #circuit-show .standard-card{
    padding-right:30px;
    padding-left:30px;
    padding-top:15px;
  }
  #circuit-show .secondary-information {
    padding-right:70px;
    padding-left:70px;
  }

  /* circuit */
  .circuit-title {
    font-size: 25px!important;
    font-weight: bold!important;
    line-height: 30px!important;
    margin-top: 20px;
  }
  /* circuit yt video */
  #circuit-show {
    #youtube-video-iframe {
      display:block;
      width: 100%;
      height: 100%;
      max-height: 372px;
      min-width: 365px;
      min-height: 214px;
      margin-top: 0px;
      padding: 21px 21px 0 21px;
    }
  }

  // news
  .oembed-provider-youtube iframe {
    width: 360px;
    height: 203px;
  }
  // news
  .oembed-provider-youtube iframe {
    width: 360px;
    height: 203px;
  }

  .banner-container{
    margin-bottom: 1em;
  }

  /* espace entre les blocs de contenu */
  .static-content {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  /* injection d'une margin-top */
  .mb-0 {
    margin-top: 50px!important;
  }

  /* phrase d'accroche */
  .dark-grey-banner .banner-title {
    font-size: 20px;
    line-height: initial!important;
  }

  #about-us-page {
    .first-content {
      p {
        font-size: 15px;
      }
    }
  }

  #about-us-page .nos-circuits, #about-us-page .nos-partenaires {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px!important;
    margin-bottom: 10px!important;;
  }

  .createButtons {
    width: 100%;
  }

  #who-anchor {
    top: -10px;
  }

  .registration-card .col-6, .registration-card  .mb-3 {
    padding-left: 0!important;
    padding-right: 0!important;
  }

  /* icone maintenance */
  .position-corner {
    position: absolute;
    top:5px;
    right:-30px;
  }

  .p-5 {
    padding: 1rem!important;
  }

  .p-6 {
    padding: 0.30rem * 4.5 !important;
    padding-top: 0.30rem * 4 !important;
    padding-bottom: 0.30rem * 0 !important;
  }

  .col-6 {
    flex: auto!important;
    max-width: 100%!important;
  }

  .col-12, .col-lg-4 {
    padding-left: 0px;
    padding-right: 0px;
  }

  /* bouton valider de la page contact */
  .contact-section .col-12 .col-sm-12{
    padding:0;
    margin:0 !important;
    width: auto;
  }

  /* detail poste sur google maps */
  .firstHeading{
    font-size: 20px;
  }
  .gm-style-iw-d #content img{
    max-width: 200px;
  }
  .gm-style .gm-style-iw-c {
    max-width: 300px!important;
    max-height: 450px!important;
  }
  /*padding moins gourmand sur les cards*/
  .global-card {
    padding-left: 5px!important;
    padding-right: 5px!important;
  }
  .common-card{
    margin:5px;
    padding:5px;
  }
  /* fix boutons prec/suiv sur la réservation*/
  .col-6 {
    max-width:50%!important;
  }

  .table {
    font-size: 10px!important;
    width:95%!important;
  }
  .table th{
    font-size: 11px!important;
  }
}