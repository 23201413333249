
// Override default bootstrap carousel

// Container of indicators
.carousel-indicators {
    flex-direction: column;
    right: 0px;
    left: auto;
    top: auto;
    bottom: auto;
    margin-right: 0px;
    margin-top: auto;
    margin-bottom: auto;
    width: 32px;
    height: 100%;
  }

// Style for indicator
.carousel-indicators li {
width: 8px;
height: 8px;
border-radius: 100%;
border-style: solid;
border-color: white;
border-width: 2px;
background-color: transparent;
margin-top:2px;
margin-bottom:2px;
  &.active{
    position:relative;
    overflow:hidden;
    &::after{
      content:'';
      top:0;
      bottom:0;
      right:0;
      left:0;
      position:absolute;
      background-color:white;
    }
  }
}

/*
#roundCarousel {
  position: relative;
}
*/

.carousel-inner .special-button{
  font-weight:400;
  text-transform: uppercase;
  font-family: 'Neutra2Text-Demi';
  font-size:15px;
  border-radius:0;
  display: flex;
  justify-content: center;
  align-items: center;
  i{
    font-size:19px;
    margin-top:-3px;
  }
}