.top-carousel-caption {
    @extend .carousel-caption;
    top: 0px;
    bottom: auto;
    background-color: rgba(0, 0, 0, 0.8);
}

.middle-carousel-caption {
    @extend .carousel-caption;
    top: 35%;
    bottom: auto;
    padding: 5px;
    width: auto;
    background-color: rgba(0, 0, 0, 0.8);
}

.bottom-carousel-caption {
    @extend .carousel-caption;
    top: auto;
    bottom: 0px;
}