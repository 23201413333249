.page-item .page-link,
.page-item.active .page-link {
    z-index: 1;
    background-color: white;
    color: $paginator-txt-color;
    border-style: solid;
    border-color: $paginator-border-color-active;
  }

.page-item .page-link.page-nav-button,
.page-item.disabled .page-link.page-nav-button
 {
    z-index: 0;
    color: $paginator-txt-color;
    border-style: none;
    font-weight: bold;
}

.paginator-pos {
  margin-left: auto;
  margin-right: 0px;
}