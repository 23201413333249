
// Styles for menu, footer and main content
html {
    background-color: $bg-color;
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    min-height: 100%;
    background-color: transparent;
}

header, footer {
    flex-grow: 0;
}

header {
    font-family: "Neutra2Text-Bold";
    color: $header-txt-color;
    font-size: 12px;

    .dropdown-toggle, .dropdown-menu, .dropdown-item {
        color: inherit;
    }

    .btn {
        font-size: inherit;
    }
}

.main-container{
    margin: 70px 0 70px 0;
}

.row{
    margin-left: 0;
    margin-right: 0;;
}

main {
    display: block;
    //flex-grow: 1;
    margin: 0px;
    padding: 0px;
}

footer {
    font-family: 'Neutra2Text-Demi';
    font-weight: normal;
    text-decoration: none;
    width: 100%;

    color: $footer-txt-color;
    
    

    #footer-top {
       
        background-color: $dark-grey;
        padding: 30px 20px;
        @include media-breakpoint-down(lg){
            .col-sm-4{
                margin-bottom:20px;
                &:last-of-type{
                    margin-bottom:0;
                }
            }
        }
        h2 {
           font-size: 13px;
           margin: 0px;
           padding: 0px;
           letter-spacing: 0px;
        }

        h3 {
            font-size: 25px;
            margin: 0px;
            padding: 0px;
            color:white;
            font-family: "Neutra2Text-Demi";
        }
    }

    #footer-bot {
        background-color: black;
       
        padding-top:30px;
        padding-bottom:50px;

        h3{
            font-size: 14px;
            letter-spacing: 0.8px;
            color: white;
            font-weight: 400 !important;
        }

        .footer-pages, .footer-link{
            @include media-breakpoint-down(md) {
                padding-bottom:30px;
            }
            .col-sm-6{
                &:first-of-type{
                    padding-left:0;
                    
                }
                @include media-breakpoint-down(md) {
                    padding-left:0;
                    padding-right:0;
                }
            }
            a{
                font-size: 12px;
                text-transform: uppercase;
                letter-spacing: 0px;
                line-height: 25px;
            }
        }

        .subscribe{
            display: flex !important;
            align-items: center;
            h3{
                text-decoration:underline;
            }
        }
    }

    /*
    .container {
        background-color: $footer-bg-color;
    }*/

    a {
        font-family: inherit;
        color: inherit;
        text-decoration: inherit;
    }

    ul {
        font-family: inherit;
        color: inherit;
        text-decoration: inherit;
        list-style-type: none;
        margin: 0;
        padding: 0;
        font-size: 17px;
    }

    h1 {
        font-family: inherit;
        color: inherit;
        text-decoration: inherit;
        font-size: 17px;
        font-weight: normal;
    }

    h2 {
        font-family: inherit;
        color: inherit;
        text-decoration: inherit;
        font-size: 17px;
        font-weight: normal;
    }

    .form-control {
        font-family: inherit;
        color: inherit;
        background-color: inherit;
        text-decoration: inherit;
        border: 2px solid $footer-txt-color;
    }
}

.container-fluid {
    height: 100%;
}


/* additionnal bootstrap */
.p-6 {
    padding: $spacer * 4.5 !important;
    padding-top: $spacer * 4 !important;
    padding-bottom: $spacer * 0 !important;
}

.light-grey-card, .dark-grey-card {
    min-height: 625px;
}

.static-content {
    background-color: white;
    padding-top: 150px;
    padding-bottom: 150px;
}

#who-anchor {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
}

.createButtons {
    width: 225px;
    margin-right: 15px;
}

/* phrase d'accroche */
.dark-grey-banner .banner-title {
    font-size: 30px;
    line-height: initial!important;
}

button.btn{
    &:hover{
        background-color: #7d7468;
        border-color: #766d63;
    }
    a:hover{
        text-decoration: none !important;
    }
}
.image-banner{
    &:hover{
        background-color: #7d746852;
        border-color: #766d6352;
        span{
            text-decoration: none !important;
        }
    }
}
.right-menu{
    .py-2{
        .dropdown-item:hover{
            background:transparent;
        }
    }
}