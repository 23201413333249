.page-history{
  .history-dates{
    position: absolute;
    right: 15px;
    bottom: 5px;
    top: 50px;
    display: flex;
    align-items: center;
    a{
      padding-right:13px;
      padding-left:13px;
      position:relative;
      font-family: 'Neutra2Text-Book';
      &:last-of-type{
        padding-right:0;§
        &::after{
          display:none;
        }
      }
      &::after{
        content:'';
        position:absolute;
        width:1px;
        height:10px;
        right:0;
        top:0px;
        bottom:0;
        margin:auto;
        background:#91877B;
      }
    }
  }


  .history-liste-dates{
    > .col-12{
      padding-top:60px;
      // écarter l'ancre du haut de l'écran à cause du header fixe
      scroll-margin-top: 120px;

      &:first-of-type{
        padding-top:20px;
      }

      .history-left{

        .row-title{
          position:relative;
          &::after{
            content:'';
            position:absolute;
            top:0;
            bottom:0;
            left:0;
            right:40%;
            height:1px;
            width:auto;
            background:$h3-color;
            margin:auto;
          }
        }
        h2{
          padding-left:calc(60% + 30px);
          
        }
      }

      
      .history-right{
        .row-title{
          position:relative;
          &::after{
            content:'';
            position:absolute;
            top:0;
            bottom:0;
            left:35%;
            right:0;
            height:1px;
            width:auto;
            background:$h3-color;
            margin:auto;
          }
        }
        .row-content{
          flex-direction: row-reverse;
        }
        h2{
          text-align:right;
          padding-right:calc(65% + 30px);
        }
      }

      .history-left, .history-right{
        background-color: white;
        padding-left:15px !important;
        padding-right:15px !important;
        .row-title{
          margin-bottom:30px;
          h2{
            font-family: 'Neutra2Text-Book';
            font-size:40px;
          }
        }
        .row-content{
            .historical-image{
              display:flex;
              justify-content: center;
            }
          .col-6{
            padding: 0 7%;
            p{
              letter-spacing: 0px;
              line-height: 24px;
            }
            strong{
              color:$h3-color;
            }
          }
          margin-bottom:20px;
        }
      }
    }
  }

  .other-circuits{
    margin-top:60px;
    h2{
      color: $h3-color;
      font-family: "Neutra2Text-Book";
      font-size: 30px;
    }
  }
}