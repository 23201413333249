hr {
    border-color: $menu-border-color;
}

th {
    font-family: 'Neutra2Text-Bold';
    font-size: 15px;
    font-weight: bold;
    color: $th-color;
    border: none;
}

